import { useStateContext } from '../contexts/ContextProvider';
import { Navbar, Sidebar, Menu } from './index';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

const Layout = ({ children }) => {
    const { activeMenu } = useStateContext();
    const [showRightSideMenu, setShowRightSideMenu] = useState(false);

    return (
        <div className='flex relative bg-main-dark-bg p-0'>
            <div className='fixed right-4 bottom-4' style={{ zIndex: '1000' }}>
            </div>
            {activeMenu ? (
                <div className='w-56 fixed sidebar bg-secondary-dark-bg bg-white'>
                    <Sidebar setShowRightSideMenu={setShowRightSideMenu} />
                </div>

            ) : (
                <div className='w-0 bg-secondary-dark-bg'>
                    <Sidebar setShowRightSideMenu={setShowRightSideMenu} />
                </div>
            )}
            <div className={
                `bg-main-dark-bg  min-h-screen w-full`}>
                <div className='fixed md:static bg-main-dark-bg navbar w-full'>
                    <Navbar setShowRightSideMenu={setShowRightSideMenu} />
                </div>
                {showRightSideMenu && <Menu setShowRightSideMenu={setShowRightSideMenu} />}
                <div className='flex flex-wrap lg:flex-nowrap justify-center flex-col items-center'>
                    {children}
                </div>
            </div>
            <NavLink
                className={'p-5 mb-5 text-2xl rounded-full'}
                to={'/transactions'}
                key={'transaction'}
            >             
                <div style={{ color: 'rgb(153, 171, 180)', borderRadius: '50%' }}
                    className="fixed bottom-10 right-10 text-white bg-white py-3 px-5">+
                </div>
            </NavLink>

        </div>
    )
}

export default Layout;
