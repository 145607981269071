import React, { useEffect, useState } from 'react';
import { BsCurrencyDollar } from 'react-icons/bs';
import { Header } from '../components';
import { Layout } from '../components';
import { useStateContext } from '../contexts/ContextProvider';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid';
import { Link } from 'react-router-dom';
import { Loader } from '../components';
import { HiMiniBuildingOffice } from "react-icons/hi2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, LinearScale, PointElement, LineElement } from 'chart.js';
import { CategoryScale } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement);

const Transactions = () => {
    const [accounts, setAccounts] = useState([]);
    const [transactionName, setTransactionName] = useState('');
    const [transactionTypeId, setTransactionTypeId] = useState('');
    const [transactionTypes, setTransactionTypes] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [transactionNames, setTransactionNames] = useState([]);
    const [accountId, setAccountId] = useState('');
    const [transactionAmount, setTransactionAmount] = useState('');
    const { getUsersAccounts, createTransaction, deleteTransaction, getTransactionTypes, getUsersTransactions, getUsersTransactionNames } = useStateContext();
    const orderedByDateTransactions = transactions.sort((a, b) => {
        return new Date(b.Transactions.CreatedDate) - new Date(a.Transactions.CreatedDate);
    });
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [accountsData, transactionsData, transactionTypesData, transactionNamesData] = await Promise.all([
                    getUsersAccounts(setLoader),
                    getUsersTransactions(),
                    getTransactionTypes(),
                    getUsersTransactionNames()
                ]);
                setAccounts(accountsData?.accounts || []);
                setTransactions(transactionsData?.transactions || []);
                setTransactionTypes(transactionTypesData?.transactionTypes || []);
                setTransactionNames(transactionNamesData?.transactionNames || []);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setLoader(false);
            }
        };
        fetchData();
    }, [loader]);

    const handleCreateTransaction = (e) => {
        e.preventDefault()
        if (!transactionName) {
            toast("Transaction name can not be empty!");
            return false;
        }
        if (!accountId) {
            toast("Please select an account!");
            return false;
        }
        if (!transactionTypeId) {
            toast("Please select transaction type!");
            return false;
        }
        if (transactionAmount === 0 || transactionAmount === '') {
            toast("Please provide transaction amount!");
            return false;
        }
        createTransaction({
            Id: uuidv4(),
            TransactionName: transactionName,
            AccountId: accountId,
            TransactionAmount: transactionAmount.replace(',', '.'),
            TransactionTypeId: transactionTypeId
        }, setLoader)
        setTransactionAmount('');
    }
    const handleDeleteTransaction = (id) => {
        deleteTransaction(id, setLoader)
    }
    return (
        <Layout>
            <ToastContainer />
            <div className="w-5/6 m-auto m-2 md:m-10 mt-10 p-5 md:p-10 bg-secondary-dark-bg rounded-3xl">
                {loader && <Loader />}
                <Header category="App" title="Transactions" />
                <div className='mt-5 w-full md:w-400'>
                    <form className="max-w-md mx-auto mb-5"> 
                        <div className="relative z-0 w-full mb-5 group">
                            <h3 className="mb-4 text-gray-500 text-sm font-sm">Transaction name</h3>
                            <input
                                onChange={(e) => setTransactionName(e.target.value)}
                                value={transactionName}
                                type="text"
                                className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-2 border-gray-300 appearance-none rounded-md p-2" />
                                                            <ul className=" flex flex-wrap mb-4">
                                {
                                    transactionNames?.map((item) => (
                                        <li key={item.Id} className='mx-1 my-2'>
                                            <label htmlFor={item.Id} className="block relative">
                                                <input id={item.Id} onChange={(e) => setTransactionName(item.Name)} type="radio" name="transactionName" className="sr-only peer" />
                                                <div className="w-full p-2 cursor-pointer rounded-lg border bg-gray-600 shadow-sm ring-white peer-checked:ring-2 duration-200">
                                                    <h3 className="leading-none text-gray-400 font-medium text-sm">
                                                        {item.Name}
                                                    </h3>
                                                </div>
                                            </label>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                            <h3 className="mb-2 text-gray-500 text-sm font-sm">Source account</h3>
                            <ul className="flex flex-wrap mb-4">
                                {accounts.length === 0
                                    && <Link className=" px-5 py-1 bg-blue-400 rounded-lg text-white" to="/accounts">Add an account</Link>

                                }
                                {
                                    accounts?.map((item) => (
                                        <li key={item.Id} className='mx-1 my-2'>
                                            <label htmlFor={item.Id} className="block relative">
                                                <input id={item.Id} onChange={() => setAccountId(item.Id)} type="radio" name="AccountId" className="sr-only peer" />
                                                <div className="w-full p-2 cursor-pointer rounded-lg border bg-gray-600 shadow-sm ring-white peer-checked:ring-2 duration-200">
                                                    <h3 className="leading-none text-gray-400 font-medium text-sm">
                                                    <HiMiniBuildingOffice style={{fill: item.AccountColor}} className='inline' /> {item.AccountName}
                                                    </h3>
                                                </div>
                                            </label>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                            <h3 className="mb-2 text-gray-500 text-sm font-sm">Transaction type</h3>
                            <ul className=" flex flex-wrap mb-4">
                                {
                                    transactionTypes?.map((item) => (
                                        <li key={item.Id} className='mx-1 my-2'>
                                            <label htmlFor={item.Id} className="block relative">
                                                <input id={item.Id} onChange={(e) => setTransactionTypeId(item.Id)} type="radio" name="transactionType" className="sr-only peer" />
                                                <div className="w-full p-2 cursor-pointer rounded-lg border bg-gray-600 shadow-sm ring-white peer-checked:ring-2 duration-200">
                                                    <h3 style={{
                                                        color: item.Id === "TT2" ? "salmon" : "lightgreen"
                                                    }} className="leading-none text-gray-400 font-medium text-sm">
                                                        {item.TransactionType}
                                                    </h3>
                                                </div>
                                            </label>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                        <div className="relative z-0 w-full mb-5 group">
                            <h3 className="mb-4 text-gray-500 text-sm font-sm">Transaction amount</h3>
                            <input
                                type="text" 
                                inputmode="decimal"
                                onChange={(e) => setTransactionAmount(e.target.value)}
                                value={transactionAmount}
                                className="block py-2.5 px-0 w-full text-sm text-gray-500 bg-transparent border-2 border-gray-300 appearance-none rounded-md p-2" />
                        </div>
                        <button onClick={handleCreateTransaction} className="bg-gray-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center text-gray-300">Create</button>
                    </form>
                    {orderedByDateTransactions?.map((item, index) => (
                        <div key={index} className='relative bg-gray-600 flex justify-between items-center mt-2 p-2 rounded-2xl hover:shadow-lg cursor-pointer'>
                            <div className='flex gap-4'>
                                <button
                                    type='button'
                                    style={{
                                        color: item.Transactions.TransactionTypeId === "2" ? "red" : "green",
                                        backgroundColor: item.Transactions.TransactionTypeId === "2" ? "salmon" : "lightgreen"
                                    }}
                                    className='text-2xl rounded-lg p-4 hover:drop-shadow-xl'
                                >
                                    <BsCurrencyDollar />
                                </button>
                                <div>
                                    <p className='text-md text-gray-200 font-semibold'>
                                        {item.Transactions.TransactionName}
                                    </p>
                                    <p className='text-sm text-gray-400'>
                                        $ {item.Transactions.TransactionAmount}
                                    </p>
                                    <p className='text-xs text-gray-400'>
                                        <HiMiniBuildingOffice style={{fill: item.AccountColor}} className='inline' /> {item.Accounts.AccountName} $ {item.Transactions.BalanceAfterTransaction}
                                    </p>
                                </div>
                            </div>
                            <button
                                onClick={() => handleDeleteTransaction(item.Transactions.Id)}
                                className="absolute top-3 right-3">
                                <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 4a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v2h4a1 1 0 1 1 0 2h-1.069l-.867 12.142A2 2 0 0 1 17.069 22H6.93a2 2 0 0 1-1.995-1.858L4.07 8H3a1 1 0 0 1 0-2h4V4zm2 2h6V4H9v2zM6.074 8l.857 12H17.07l.857-12H6.074zM10 10a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1zm4 0a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0v-6a1 1 0 0 1 1-1z" fill="gray" /></svg>
                            </button>
                        </div>
                    ))}
                </div>
            </div>
        </Layout>
    );
};

export default Transactions;
